import { Link, json, useNavigate, useParams } from 'react-router-dom';
import './stylesheets/ask.css';
import React, { useContext, useEffect, useState } from 'react';
import { auth, db} from './firebase';
import { AuthContext } from './components/AuthProvider';
import { collection, addDoc, getDocs, query, where, doc, getDoc, setDoc, onSnapshot, serverTimestamp } from "firebase/firestore"; 
import { async } from '@firebase/util';
import { getNewID } from './utils';
import calculateTokens from './components/utils';

const Ask = () => {
  const {currentUser} = useContext(AuthContext);
  
  // const jsonConvers = require("./json/conversations.json");
  // console.log(jsonConvers);

  let navigate = useNavigate();

  // If user not logged in, navigate to the sign-in page
  if (!currentUser) {
    // navigate('/signin');
    window.location.href = "/signin";
  }

  //Displays the scrollpaper and hides the pipe
  if(document.querySelector(".navbar")){
    if(auth.currentUser){
      if(document.querySelector(".signoutLink").classList.contains("hide")){
          document.querySelector(".signoutLink").classList.remove("hide");
      }
    }else{
        if(!document.querySelector(".signoutLink").classList.contains("hide")){
            document.querySelector(".signoutLink").classList.add("hide");
        }
    }

    if(document.querySelector(".chestLink").classList.contains("hide")){
      document.querySelector(".chestLink").classList.remove("hide");
    }
    if(!document.querySelector(".pipeLink").classList.contains("hide")){
      document.querySelector(".pipeLink").classList.add("hide");
    }
  }
  
  //Resets the backgroundimage of body
  setTimeout(() => {
    let bodysClasslist = document.body.classList;
    if(bodysClasslist.length !== 0){
        bodysClasslist.remove(bodysClasslist.item(0));
    }
  }, 300);

  //Consts
  const { selectedGandalf } = useParams();

  //ClickAPI - URL
  const apiURL = "https://clickapi-hce5xudmta-ew.a.run.app";
  // const apiURL = "http://localhost:5001";
  const authorizationToken = "gandalf";

  const [idea, setIdea] = useState('');
  const [usersData, setUsersData] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [imagesGenerated, setImagesGenerated] = useState([]);
  const [conversImagesArray, setConversImagesArray] = useState([]);
  const [gandalfThinking, setGandalfThinking] = useState(false);
  const [gandalfAvailable, setGandalfAvailable] = useState(true);
  const [saving, setSaving] = useState(false);
  const [newSectionID, setNewSectionID] = useState(getNewID(currentUser.metadata.lastLoginAt));
  const userUid = currentUser.uid;

  // const usersCollection = collection(db, "users");
  //Updates the database; usersData
  useEffect(
    ()=>
      onSnapshot(collection(db, "users", userUid, "sections"), (snapshot)=>
        setUsersData(snapshot.docs.map((doc) => ({...doc.data(),id: doc.id}))) 
      ),
    []
  );

  const getDate = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();    

    return currentHour +":"+currentMinute+" - "+currentDay+"/"+currentMonth+"/"+currentYear;
  }

  const getTime = () => {
    // const currentMinute = new Date().getMinutes();    
    // const currentSecond = new Date().getSeconds();

    // return  currentMinute + ":"+currentSecond;
    const time = new Date().getTime();
    return time;
  }

  const saveToQuerry = (element) => {
    fetch(apiURL+"/postToQuerry", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json", 
        "Authorization": `${authorizationToken}`
      },
      body: JSON.stringify({
        element
      }), // body data type must match "Content-Type" header
    }).then(r => r.json()).then(d => console.log(d));
  }

  const handleClick = (element) => {
    // let clickedElement = 'User clicked to button: "' + element.innerText + '"';

    let currentDate = getDate();
    let timeBefore = getTime();

    setGandalfThinking(true);

    //Reformated array to send to AI
    let tokenCalculatedConversations = calculateTokens(conversation, selectedGandalf, idea);
    let conversationsToSend = tokenCalculatedConversations.reverse();

      fetch(apiURL+"/postNewIdea", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json", 
          "Authorization": `${authorizationToken}`
        },
        body: JSON.stringify({
          title: idea,
          date: currentDate,
          response: idea,
          conversations: conversationsToSend,
          hat: selectedGandalf
        }), // body data type must match "Content-Type" header
      }
      ).then(response => {
        if (response.ok) {
          setGandalfAvailable(true);
          return response.json();
        }else{
          setGandalfAvailable(false);
        }
      }
      ).then(data => {
        if(data){
          let timeAfter = getTime();
          let responceTimeFromClickAPI = parseInt(timeAfter) - parseInt(timeBefore);
          
          data["responceTimeFromClickAPI"] = responceTimeFromClickAPI;
            
          setIdea('');
          console.log(data);
          
          conversation.push(data);
          setConversation(conversation.sort((a, b) => b.Id - a.Id));
          
          conversImagesArray.reverse();
          conversImagesArray.push(data);
          setConversImagesArray(conversImagesArray.reverse());
        }
        setGandalfThinking(false);
      });
  }

  const generateImage = () => {
    setGandalfThinking(true);

    fetch(apiURL+"/generateImage", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json", 
        "Authorization": `${authorizationToken}`
      },
      body: JSON.stringify({
        description: idea,
        date: getDate(),
      }), // body data type must match "Content-Type" header
  }
  ).then(response => {
      if (response.ok) {
        setGandalfAvailable(true);
        return response.json();
      }else{
        setGandalfAvailable(false);
      }
    }
  )
  .then(data => {
    if(data){
      setIdea('');
      imagesGenerated.push(data);
      setImagesGenerated(imagesGenerated.sort((a, b) => b.Id - a.Id));
      console.log(imagesGenerated);
      
      conversImagesArray.reverse();
      conversImagesArray.push(data);
      setConversImagesArray(conversImagesArray.reverse());
    }
    setGandalfThinking(false);
  })
}

  const saveConversation = async () =>{
    //Saves to clickAPI
  //   fetch(apiURL+"/postConversation", {
  //     method: "POST", // *GET, POST, PUT, DELETE, etc.
  //     // mode: "cors", // no-cors, *cors, same-origin
  //     headers: {
  //       "Content-Type": "application/json", 
  //       "Authorization": `${authorizationToken}`
  //     },
  //     body: JSON.stringify({
  //       title: conversation[0].Title,
  //       date: conversation[0].Date,
  //       hat: selectedGandalf,
  //       conversation: conversation
  //     }), // body data type must match "Content-Type" header   
  // }).then(response => response.json()).then(data => {
  //   console.log(data);
  // });

    setSaving(true);

    //Saves to firestore    
    const userSectionsCollection = collection(db, "users", userUid, "sections");
    
    let newSection = {
      sectionID: newSectionID,
      conversations: conversation,
      timestamp: serverTimestamp(),
      date: getDate(),
      uid: userUid
    };

    try {
      //Creates a newSection in sections with an id generated by firestore
      const docRef = await addDoc(userSectionsCollection, newSection);
        
      // const docRef = conversation.length <= 1 ? doc(userSectionsCollection, lastID) : doc(userSectionsCollection, newID);

      //Creates a newSection in sections with an customid generated by me! :)
      // const docRef = doc(userSectionsCollection, newSectionID); // Use your custom ID

      await setDoc(docRef, newSection);
      console.log("Document successfully written with ID: ", docRef.id);

      setSaving(false);
      // navigate("/history");
      document.querySelector(".scrollpaperBTN").click();
    } catch (error) {
      console.error("Error fetching user's sections: ", error);
    }
  }

  const newConversation = () =>{
    setConversation([]);
    setConversImagesArray([]);
    setIdea('');
  }

  return (
    <div  className={`ask-container ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
      <div className='responseBox'>
        {gandalfAvailable === false && (
          <div className={`avaliableBox ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
            Gandalf is not available right now! Please try later!
          </div>
        )}
        {gandalfThinking && (
          <div className={`thinkingBox ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
            <div className="lds-facebook">
              <div className={`${selectedGandalf === 'green' ? 'thinkingGreen' : 'thinkingRed'}`}></div>
              <div className={`${selectedGandalf === 'green' ? 'thinkingGreen' : 'thinkingRed'}`}></div>
              <div className={`${selectedGandalf === 'green' ? 'thinkingGreen' : 'thinkingRed'}`}></div>
            </div>
          </div>
        )}

          {conversImagesArray.length !== 0 && (
            conversImagesArray.map(round => {
              return (
                <div className={`ideaBox ${selectedGandalf === 'green' ? 'green' : 'red'}`} key={conversImagesArray.indexOf(round)}>
                  <p className='padding'><span className='bold'>{currentUser.displayName}</span>: {round.Title}</p>
                  <p className='date'>{round.Date}</p>
                  {round.Response ? <>
                    <p className='padding'><span className='bold'>{selectedGandalf === "red" ? "Gandalf The Red" : "Gandalf the Green" }</span>: {round.Response}</p>
                  </> 
                  :
                  <>
                    {round.url.map(ur => {
                        return (
                          <img key={round.url.indexOf(ur)} className='imgGenerated' src={ur.url} alt='img' />
                        )
                      }) }
                  </>  
                }
                  {saving && (
                    <div className='savingBox'>
                      <div className={`lds-ring ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })
          )}


        {/* <div className='textToImageBox responsDiv'>
          {imagesGenerated.length !== 0 && (
            imagesGenerated.map(round => {
              return (
                <div className={`ideaBox ${selectedGandalf === 'green' ? 'green' : 'red'}`} key={imagesGenerated.indexOf(round)}>
                  <p className='padding'><span className='bold'>{currentUser.displayName}</span>: {round.promptToAI}</p>  
                    {round.url.map(ur => {
                      return (
                        <img className='imgGenerated' src={ur.url} alt='img' />
                      )
                    }) }
                </div>
              )
            })
          )}
        </div>  */}

      </div>
      <div className='conversationDiv'>
        {conversation.length !== 0 && (
          <div className={`conversationImgDiv ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
            <img onClick={saveConversation} src={require(`./assets/${selectedGandalf}Chest.png`)} alt={selectedGandalf + "chest"}/>
            <p>Save</p>
          </div>
        )}

        <div className='askBox'>
                <textarea 
                    type="text" 
                    id="theIdea"
                    autoComplete="off"
                    className='ideaInp'
                    onChange={(e) => setIdea(e.target.value)}
                    required
                    placeholder='What are you thinking?'
                    value={idea}
                />
                <button className='sendBtn' onClick={(e) => handleClick(e.target)}>Ask</button>
                <button className='sendBtn' onClick={(e) => generateImage()}>Visualize</button>
          {/* <div className={`conversationImgDiv ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
            <img id='pipeImgBtn' onClick={handleClick} src={require(`./assets/pipe.png`)} alt="pipe" />
            <p>Let´s pipe</p>
          </div> */}
        </div>
     
        {conversation.length !== 0 && (
          <div className={`conversationImgDiv ${selectedGandalf === 'green' ? 'green' : 'red'}`}>
            <img className='scrollpaperBTN' onClick={newConversation} src={require(`./assets/scrollpaper.png`)} alt="scrollpaper" />
            <p>Clear</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ask;